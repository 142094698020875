import { Navigate, Outlet, matchRoutes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUserRules } from "./authSlice";
import ROUTES_OBJ from "utilities/enums/Routes";

const CheckPermission = () => {
  const user_rules = useSelector(selectCurrentUserRules);
  const location = useLocation();
  const match = matchRoutes(Object.values(ROUTES_OBJ), location.pathname.replace("/admin", ""));
  const { print_scenarios_disabled, takeaway_enabled } = useSelector((state) => state.settings);
  if (match === null || match.length === 0)
    return <Navigate to="/admin/unauthorized" state={{ from: location }} replace />; // if no match redirect to unauthorized page
  const route = match[0].route;
  const permissionLocation = `Route:${route.path.replace("/admin/", "")}`;
  const permissionCheck =
    user_rules && user_rules.find((rule) => rule.name === permissionLocation && rule.value === true);
  const isScenariosPage = location.pathname === `/admin/${ROUTES_OBJ.SETTINGS_PRINT_SCENARIOS.path}`;
  const isTakeawayPage = location.pathname === `/admin/${ROUTES_OBJ.TAKEAWAY.path}`;
  const checkTakeawayDisabled = !takeaway_enabled && isTakeawayPage;
  // if print scenarios is disabled and the user is on the scenarios page, redirect to unauthorized page
  const checkScenariosDisabled = print_scenarios_disabled && isScenariosPage;
  const userCanAccess =
    !checkScenariosDisabled &&
    !checkTakeawayDisabled &&
    (permissionCheck || location.pathname === "/admin/unauthorized");
  return userCanAccess ? <Outlet /> : <Navigate to="/admin/unauthorized" state={{ from: location }} replace />;
};
export default CheckPermission;
