import Analytics from "views/pages/Analytics";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Categories from "views/pages/menu-management/Categories/Categories";
import Tables from "views/pages/tables/Tables.js";
import TableActions from "views/pages/tables/TableActions";
import Subcategories from "views/pages/menu-management/Subcategories/Subcategories";
import NewOrderCategories from "views/pages/tables/NewOrder/NewOrderCategories";
import NewOrderSubCategories from "views/pages/tables/NewOrder/NewOrderSubCategories";
import NewOrderProducts from "views/pages/tables/NewOrder/Products/NewOrderProducts";
import NewOrderProduct from "views/pages/tables/NewOrder/Product/NewOrderProduct";

import Products from "views/pages/menu-management/Products/Products";
import NewProduct from "views/pages/menu-management/Products/NewProduct";
import EditProduct from "views/pages/menu-management/Products/EditProduct";
import Variations from "views/pages/menu-management/Variations/Variations";
import VariationOptions from "views/pages/menu-management/VariationOptions/VariationOptions";

import Orders from "views/pages/orders/Orders";
import ViewOrder from "views/pages/orders/ViewOrder";
import Login from "views/pages/examples/Login";
import TransferOrder from "views/pages/tables/Transfer/TransferOrder";
import ResubmitOrder from "views/pages/tables/ResubmitOrder/ResubmitOrder";
import TableSummary from "views/pages/tables/TableSummary/TableSummary";
import PayItems from "views/pages/tables/PayItems/PayItems";
import Floors from "views/pages/table-management/Floors/Floors";
import TablesPage from "views/pages/table-management/Tables/TablesPage";
import { Views } from "utilities/enums/Views";
import UserManagement from "views/pages/settings/UserManagement/UserManagement";
import { PrinterManagement } from "views/pages/settings/PrinterManagement/PrinterManagement";
import General from "views/pages/settings/General/General";
import NewCategory from "views/pages/menu-management/Categories/NewCategory";
import EditCategory from "views/pages/menu-management/Categories/EditCategory";
import NewSubcategory from "views/pages/menu-management/Subcategories/NewSubcategory";
import EditSubcategory from "views/pages/menu-management/Subcategories/EditSubcategory";
import EditPrinter from "views/pages/settings/PrinterManagement/EditPrinter";
import Unauthorized from "views/pages/Unauthorized";
import ROUTES_OBJ from "utilities/enums/Routes";
import { PermissionManagement } from "views/pages/settings/PermissionManagement/PermissionManagement";
import CancelGift from "views/pages/tables/CancelGift/CancelGift";
import { PrintScenarios } from "views/pages/settings/PrintScenarios/PrintScenarios";
import Home from "views/pages/guides/Home";
import { PosTerminals } from "views/pages/cash-register/PosTerminal/PosTerminals";
import Takeaway from "views/pages/takeaway/Takeaway";
import TakeawaySubCategories from "views/pages/takeaway/TakeawaySubCategories";
import TakeawayProducts from "views/pages/takeaway/products/TakeawayProducts";
const menu = [
  {
    hidden: true,
    path: ROUTES_OBJ.LOGIN.path,
    component: <Login />,
    layout: "/auth",
  },
  {
    name: Views.DASHBOARD,
    path: ROUTES_OBJ.DASHBOARD.path,
    icon: "ni ni-shop text-primary",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    name: Views.ORDERS,
    path: ROUTES_OBJ.ORDERS.path,
    icon: "ni ni-ungroup text-orange",
    component: <Orders />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.ORDER_ID.path,
    icon: "ni ni-ungroup text-orange",
    component: <ViewOrder />,
    layout: "/admin",
  },
  {
    name: Views.TABLES,
    path: ROUTES_OBJ.TABLES.path,
    icon: "ni ni-align-left-2 text-default",
    component: <Tables />,
    layout: "/admin",
  },
  {
    name: Views.TAKEAWAY,
    path: ROUTES_OBJ.TAKEAWAY.path,
    icon: "fa fa-utensils text-default",
    component: <Takeaway />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TAKEAWAY_SUBCATEGORIES.path,
    icon: "ni ni-align-left-2 text-default",
    component: <TakeawaySubCategories />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TAKEAWAY_PRODUCTS.path,
    icon: "ni ni-align-left-2 text-default",
    component: <TakeawayProducts />,
    layout: "/admin",
  },
  {
    path: ROUTES_OBJ.ANALYTICS.path,
    name: Views.ANALYTICS,
    icon: "ni ni-chart-pie-35 text-info",
    component: <Analytics />,
    layout: "/admin",
  },
  {
    collapse: true,
    name: Views.TABLE_MANAGEMENT,
    icon: "ni ni-ui-04 text-info",
    state: "tableCollapse",
    views: [
      {
        path: ROUTES_OBJ.TABLE_MANAGEMENT_FLOORS.path,
        name: Views.FLOORS,
        miniName: "",
        component: <Floors />,
        layout: "/admin",
      },
      {
        path: ROUTES_OBJ.TABLE_MANAGEMENT_TABLES.path,
        name: Views.TABLES,
        miniName: "",
        component: <TablesPage />,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: Views.MENU_MANAGEMENT,
    icon: "ni ni-single-copy-04 text-pink",
    state: "formsCollapse",
    views: [
      {
        path: ROUTES_OBJ.MENU_MANAGEMENT_CATEGORIES.path,
        name: Views.CATEGORIES,
        miniName: "",
        component: <Categories />,
        layout: "/admin",
      },
      {
        hidden: true,
        path: ROUTES_OBJ.MENU_MANAGEMENT_NEWCATEGORY.path,
        miniName: "",
        component: <NewCategory />,
        layout: "/admin",
      },
      {
        hidden: true,
        path: ROUTES_OBJ.MENU_MANAGEMENT_CATEGORIES_ID.path,
        miniName: "",
        component: <EditCategory />,
        layout: "/admin",
      },
      {
        path: ROUTES_OBJ.MENU_MANAGEMENT_SUBCATEGORIES.path,
        name: Views.SUBCATEGORIES,
        miniName: "",
        component: <Subcategories />,
        layout: "/admin",
      },
      {
        hidden: true,
        path: ROUTES_OBJ.MENU_MANAGEMENT_NEWSUBCATEGORY.path,
        miniName: "",
        component: <NewSubcategory />,
        layout: "/admin",
      },
      {
        hidden: true,
        path: ROUTES_OBJ.MENU_MANAGEMENT_SUBCATEGORIES_ID.path,
        miniName: "",
        component: <EditSubcategory />,
        layout: "/admin",
      },
      {
        path: ROUTES_OBJ.MENU_MANAGEMENT_PRODUCTS.path,
        name: Views.PRODUCTS,
        miniName: "",
        component: <Products />,
        layout: "/admin",
      },
      {
        hidden: true,
        path: ROUTES_OBJ.MENU_MANAGEMENT_NEWPRODUCT.path,
        miniName: "",
        component: <NewProduct />,
        layout: "/admin",
      },
      {
        hidden: true,
        path: ROUTES_OBJ.MENU_MANAGEMENT_PRODUCTS_ID.path,
        miniName: "",
        component: <EditProduct />,
        layout: "/admin",
      },
      {
        path: ROUTES_OBJ.MENU_MANAGEMENT_VARIATIONS.path,
        name: Views.VARIATIONS,
        miniName: "",
        component: <Variations />,
        layout: "/admin",
      },
      {
        path: ROUTES_OBJ.MENU_MANAGEMENT_VARIATION_OPTIONS.path,
        name: Views.VARIATION_OPTIONS,
        miniName: "",
        component: <VariationOptions />,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: Views.CASH_REGISTER_MANAGEMENT,
    icon: "fas fa-cash-register text-body",
    state: "mapsCollapse",
    views: [
      {
        path: ROUTES_OBJ.POS_TERMINALS.path,
        name: Views.POS_MANAGEMENT,
        miniName: "",
        component: <PosTerminals />,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: Views.SETTINGS,
    icon: "ni ni-settings-gear-65 text-primary",
    state: "mapsCollapse",
    views: [
      {
        path: ROUTES_OBJ.SETTINGS_GENERAL.path,
        name: Views.GENERAL_SETTINGS,
        miniName: "",
        component: <General />,
        layout: "/admin",
      },
      {
        path: ROUTES_OBJ.SETTINGS_USER_MANAGEMENT.path,
        name: Views.USER_MANAGEMENT,
        miniName: "",
        component: <UserManagement />,
        layout: "/admin",
      },
      {
        path: ROUTES_OBJ.SETTINGS_PERMISSION_MANAGEMENT.path,
        name: Views.PERMISSION_MANAGEMENT,
        miniName: "P",
        component: <PermissionManagement />,
        layout: "/admin",
      },
      {
        path: ROUTES_OBJ.SETTINGS_PRINTER_MANAGEMENT.path,
        name: Views.PRINTER_MANAGEMENT,
        miniName: "",
        component: <PrinterManagement />,
        layout: "/admin",
      },
      {
        path: ROUTES_OBJ.SETTINGS_PRINT_SCENARIOS.path,
        name: Views.PRINT_SCENARIOS,
        miniName: "",
        component: <PrintScenarios />,
        layout: "/admin",
      },
      {
        hidden: true,
        path: ROUTES_OBJ.SETTINGS_EDIT_PRINTER.path,
        miniName: "",
        component: <EditPrinter />,
        layout: "/admin",
      },
    ],
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TABLE_ACTIONS.path,
    icon: "ni ni-align-left-2 text-default",
    component: <TableActions />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TABLE_ACTIONS_NEW_ORDER.path,
    icon: "ni ni-align-left-2 text-default",
    component: <NewOrderCategories />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TABLE_ACTIONS_NEW_ORDER_PARENT_CATEGORY.path,
    icon: "ni ni-align-left-2 text-default",
    component: <NewOrderSubCategories />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TABLE_ACTIONS_NEW_ORDER_PARENT_CATEGORY_PRODUCTS.path,
    icon: "ni ni-align-left-2 text-default",
    component: <NewOrderProducts />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TABLE_ACTIONS_NEW_ORDER_PRODUCT.path,
    icon: "ni ni-align-left-2 text-default",
    component: <NewOrderProduct />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TABLE_ACTIONS_TRANSFER.path,
    icon: "ni ni-align-left-2 text-default",
    component: <TransferOrder />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TABLE_ACTIONS_RESUBMIT.path,
    icon: "ni ni-align-left-2 text-default",
    component: <ResubmitOrder />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TABLE_ACTIONS_TABLE_SUMMARY.path,
    icon: "ni ni-align-left-2 text-default",
    component: <TableSummary />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TABLE_ACTIONS_PAY_ITEMS.path,
    icon: "ni ni-align-left-2 text-default",
    component: <PayItems />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.TABLE_ACTIONS_CANCEL_GIFT.path,
    icon: "ni ni-align-left-2 text-default",
    component: <CancelGift />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.GUIDES.path,
    icon: "ni ni-align-left-2 text-default",
    component: <Home />,
    layout: "/admin",
  },
  {
    hidden: true,
    path: ROUTES_OBJ.UNAUTHORIZED.path,
    icon: "ni ni-align-left-2 text-default",
    component: <Unauthorized />,
    layout: "/admin",
  },
];

export default menu;
