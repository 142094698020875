import React, { useEffect, useState } from "react";
import AlertModal from "./AlertModal";
import { t } from "i18next";
import { PaymentMethods } from "utilities/enums/PaymentMethods";
import { Input, FormGroup } from "reactstrap";
import { useSelector } from "react-redux";
import { useGetPosTerminalsQuery } from "api/posTerminalSlice";
import TipSelector from "components/Inputs/TipSelector";
import { useGetTipsPercentagesQuery } from "api/posTerminalSlice";
import { selectCurrentUserRules } from "auth/authSlice";
import ROUTES_OBJ from "utilities/enums/Routes";

export default function PaymentMethodModal(props) {
  const [selectedTerminal, setSelectedTerminal] = useState("");
  const [tipAmount, setTipAmount] = useState("0");
  const { cloud_cash_register_enabled, pos_integration_enabled, external_source_types_enabled } = useSelector(
    (state) => state.settings
  );
  const [paymentMethod, setPaymentMethod] = useState(
    external_source_types_enabled && props.isDelivery ? PaymentMethods.BANK_STATEMENT : PaymentMethods.CASH
  );
  const {
    data: posTerminals,
    isLoading: posTerminalsLoading,
    isError: posTerminalsError,
  } = useGetPosTerminalsQuery({}, { skip: !pos_integration_enabled });
  const { data: tipsPercentageOptions, isError: tipsPercentagesError } = useGetTipsPercentagesQuery(
    {},
    { skip: !pos_integration_enabled }
  );
  const user_rules = useSelector(selectCurrentUserRules);
  const pay_card_without_integration_enabled = user_rules.find(
    (rule) => rule.name === `Route:${ROUTES_OBJ.TABLE_ACTIONS_PAY_CARD_WITHOUT_INTEGRATION.path}`
  )?.value;

  let errorMsg = props.errorMsg;
  useEffect(() => {
    if (external_source_types_enabled && props.isDelivery) {
      setPaymentMethod(PaymentMethods.BANK_STATEMENT);
    }
  }, [external_source_types_enabled, props.isDelivery]);
  useEffect(() => {
    // If there is only one active terminal, select it by default
    const activeTerminals = posTerminals?.filter((terminal) => terminal.attributes.active);
    if (posTerminals && activeTerminals.length === 1) {
      setSelectedTerminal(activeTerminals[0].attributes.terminal_id);
    }
  }, [posTerminals]);

  let posTerminalSelector = (
    <FormGroup className="col-6 mb-0">
      <label className="form-control-label text-md" htmlFor="name">
        {t("Terminal")}
      </label>
      <Input
        type="select"
        name="select"
        id="posTerminalSelector"
        value={selectedTerminal}
        disabled={props.okDisabled}
        onChange={(e) => setSelectedTerminal(e.target.value)}
      >
        <option value={""}>Select terminal</option>;
        {posTerminals
          ?.filter((terminal) => terminal.attributes.active)
          ?.map((terminal) => {
            return (
              <option key={terminal.attributes.terminal_id} value={terminal.attributes.terminal_id}>
                {terminal.attributes.name}
              </option>
            );
          })}
        {pay_card_without_integration_enabled && <option value={"error_terminal"}>{t("Close_with_card")}</option>}
      </Input>
    </FormGroup>
  );

  let tipAmountInput = (
    <FormGroup className="mt-3 col-6 mb-0">
      <label className="form-control-label text-md" htmlFor="name">
        {t("Tips")}
      </label>
      <Input
        className=" form-control-alternative"
        id="tipAmmount"
        name="tipAmmount"
        placeholder={"Add tips"}
        type="text"
        value={tipAmount}
        bsSize="md"
        inputMode="decimal"
        disabled={props.okDisabled}
        onChange={(event) => {
          setTipAmount(event.target.value);
        }}
      />
    </FormGroup>
  );
  return (
    <AlertModal
      isOpen={props.isOpen}
      title={props.title}
      okText={props.okText}
      cancelText={props.cancelText}
      onCancel={props.onCancel}
      onOk={() => {
        props.onOk(paymentMethod.value, selectedTerminal, tipAmount);
      }}
      okDisabled={
        props.okDisabled ||
        (paymentMethod === PaymentMethods.CARD &&
          cloud_cash_register_enabled &&
          pos_integration_enabled &&
          !selectedTerminal)
      }
      body={
        <div className="payment-method-dialog d-flex flex-column">
          {Object.values(PaymentMethods).map((currentPaymentMethod, loopIndex) => {
            return (
              // If external source type = true && at least one delivery item is selected, show only "Bank Statement"
              // Regardless of external source type if no delivery item is selected we show only "Cash/Card"
              // props.isDelivery means at least one delivery item is selected
              // The only payment method with PaymentMethod.isDelivery is "Bank Statement"
              <div
                className={`custom-control custom-radio mb-0 ${
                  (external_source_types_enabled && props.isDelivery && !currentPaymentMethod.isDelivery) ||
                  (!external_source_types_enabled && currentPaymentMethod.isDelivery) ||
                  (external_source_types_enabled && !props.isDelivery && currentPaymentMethod.isDelivery)
                    ? `d-none`
                    : ``
                }`}
                key={loopIndex}
              >
                <input
                  className="custom-control-input"
                  id={`customRadio-${loopIndex}`}
                  name={`custom-radio-${loopIndex}`}
                  type="radio"
                  disabled={props.okDisabled}
                  checked={currentPaymentMethod === paymentMethod}
                  onChange={() => setPaymentMethod(currentPaymentMethod)}
                />

                <label
                  className={`custom-control-label large w-100 payment-method-option method-${
                    currentPaymentMethod.value
                  }  ${paymentMethod === currentPaymentMethod ? "active" : ""}`}
                  htmlFor={`customRadio-${loopIndex}`}
                >
                  {t(currentPaymentMethod.label)}
                  <div className="icon"></div>
                </label>
              </div>
            );
          })}
          {paymentMethod === PaymentMethods.CARD && cloud_cash_register_enabled && pos_integration_enabled && (
            <div className="d-flex flex-row-reverse align-items-end mt-3">
              {posTerminalSelector} {tipAmountInput}
            </div>
          )}
          {errorMsg.length > 0 && <span className="mt-3 text-danger">{errorMsg}</span>}
        </div>
      }
    />
  );
}
