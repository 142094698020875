const ROUTES_OBJ = Object.freeze({
  LOGIN: { path: "login", label: "Login" },
  DASHBOARD: { path: "dashboard", label: "Dashboard" },
  ORDERS: { path: "orders", label: "Orders" },
  ORDER_ID: { path: "orders/:orderId", label: "View_Order" },
  TABLES: { path: "tables", label: "Tables" },
  ANALYTICS: { path: "analytics", label: "Analytics" },
  TABLE_MANAGEMENT_FLOORS: { path: "table-management/floors", label: "Floors_Mangement" },
  TABLE_MANAGEMENT_TABLES: { path: "table-management/tables", label: "Table_Management" },
  MENU_MANAGEMENT_CATEGORIES: { path: "menu-management/categories", label: "Menu_management_categories" },
  MENU_MANAGEMENT_NEWCATEGORY: { path: "menu-management/new-category", label: "Menu_management_new_category" },
  MENU_MANAGEMENT_CATEGORIES_ID: {
    path: "menu-management/edit-category/:id",
    label: "Menu_management_edit_category",
  },
  MENU_MANAGEMENT_SUBCATEGORIES: { path: "menu-management/subcategories", label: "Menu_management_subcategories" },
  MENU_MANAGEMENT_NEWSUBCATEGORY: {
    path: "menu-management/new-subcategory",
    label: "Menu_management_new_subcategory",
  },
  MENU_MANAGEMENT_SUBCATEGORIES_ID: {
    path: "menu-management/edit-subcategory/:id",
    label: "Menu_management_edit_subcategory",
  },
  MENU_MANAGEMENT_PRODUCTS: { path: "menu-management/products", label: "Menu_management_products" },
  MENU_MANAGEMENT_NEWPRODUCT: { path: "menu-management/new-product", label: "Menu_management_new_product" },
  MENU_MANAGEMENT_PRODUCTS_ID: { path: "menu-management/edit-product/:id", label: "Menu_management_edit_product" },
  MENU_MANAGEMENT_VARIATIONS: { path: "menu-management/variations", label: "Menu_management_variations" },
  MENU_MANAGEMENT_VARIATION_OPTIONS: {
    path: "menu-management/variation-options",
    label: "Menu_management_variation_options",
  },
  SETTINGS_GENERAL: { path: "settings/general", label: "Settings_general" },
  SETTINGS_USER_MANAGEMENT: { path: "settings/user-management", label: "Settings_user_management" },
  SETTINGS_PERMISSION_MANAGEMENT: { path: "settings/permission-management", label: "Settings_permissions_management" },
  SETTINGS_PRINTER_MANAGEMENT: { path: "settings/printer-management", label: "Settings_printer_management" },
  SETTINGS_PRINT_SCENARIOS: { path: "settings/print-scenarios", label: "Settings_print_scenarios" },
  SETTINGS_EDIT_PRINTER: { path: "settings/edit-printer/:id", label: "Settings_edit_printer" },
  TABLE_ACTIONS: { path: "table-actions/:tableId", label: "Table_Actions" },
  TABLE_ACTIONS_TRANSFER: { path: "table-actions/transfer/:table", label: "Transfer" },
  TABLE_ACTIONS_RESUBMIT: { path: "table-actions/resubmit/:table", label: "Resubmit" },
  TABLE_ACTIONS_TABLE_SUMMARY: { path: "table-actions/table-summary/:table", label: "Table_Summary" },
  TABLE_ACTIONS_PAY_ITEMS: { path: "table-actions/pay-items/:table", label: "Pay_Items" },
  TABLE_ACTIONS_CANCEL_GIFT: { path: "table-actions/cancel-gift/:table", label: "Cancel_Gift" },
  TABLE_ACTIONS_DISCOUNT: { path: "table-actions/discount/:table", label: "Discount" },
  TABLE_ACTIONS_SUBMIT_RECEIPT: { path: "table-actions/submit-receipt/:table", label: "Submit_invoice" },
  TABLE_ACTIONS_PAY_CARD_WITHOUT_INTEGRATION: {
    path: "table-actions/pay-card-without-integration/:table",
    label: "Without_integration",
  },
  TABLE_ACTIONS_NEW_ORDER: { path: "table-actions/new-order/:table", label: "New_order_select_category" },
  TABLE_ACTIONS_NEW_ORDER_PARENT_CATEGORY: {
    path: "table-actions/new-order/:table/:parentCategory/subcategories/",
    label: "New_order_select_subcategory",
  },
  TABLE_ACTIONS_NEW_ORDER_PARENT_CATEGORY_PRODUCTS: {
    path: "table-actions/new-order/:table/:parentCategory/products",
    label: "New_order_select_procucts",
  },
  TABLE_ACTIONS_NEW_ORDER_PRODUCT: {
    path: "table-actions/new-order/:table/product/:productID",
    label: "New_order_select_product",
  },
  GUIDES: {
    path: "guides/home",
    label: "Guides",
  },
  POS_TERMINALS: {
    path: "cash-register/pos-terminals",
    label: "Pos_terminals",
  },
  TAKEAWAY: {
    path: "takeaway",
    label: "Takeaway",
  },
  TAKEAWAY_SUBCATEGORIES: {
    path: "takeaway/:parentCategory/subcategories",
    label: "Takeaway_subcategories",
  },
  TAKEAWAY_PRODUCTS: {
    path: "takeaway/:parentCategory/products",
    label: "Takeaway_products",
  },
  UNAUTHORIZED: { path: "unauthorized", label: "Unauthorized" },
});
export default ROUTES_OBJ;
