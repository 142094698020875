import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardBody, Button, CardFooter } from "reactstrap";
import { useSelector } from "react-redux";
import { useGetPendingOrdersByTableQuery } from "api/ordersSlice";
import Cart from "./Cart";
import { ListItem } from "@mui/material";
import { mergeToCommaSepString, formatCurrency } from "utilities/utils";
import PuffLoader from "react-spinners/PuffLoader";
import { getItemFromPendingOrders } from "utilities/utils";
import { useTranslation } from "react-i18next";

const MODE_CART = "Cart";
const MODE_TABLES = "Tables";

export default function CartSidebar(props) {
  const navigate = useNavigate();
  const { cartItems, cartTable } = useSelector((state) => state.cart);
  const mode = props.mode != null ? MODE_TABLES : MODE_CART;
  const currentTable = mode == MODE_TABLES ? props.mode : cartTable?.id;
  let currentTableId = parseInt(currentTable?.id);
  const {
    data: pendingOrders,
    isError,
    isFetching,
  } = useGetPendingOrdersByTableQuery(currentTableId, {
    refetchOnMountOrArgChange: 60,
    skip: currentTableId <= 0,
  });
  const { t } = useTranslation();
  let totalAmount = 0;

  let content = <></>;

  if (mode == MODE_TABLES) {
    // The loader should be displayed on the sidebar only when mode is "Tables". The Cart doesn't change if some other user submitted an order
    if (isFetching) {
      content = (
        <div className="w-100 d-flex justify-content-center">
          <PuffLoader
            loading={isFetching}
            color="#d9d9d9"
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      );
    } else {
      const pendingItems = getItemFromPendingOrders(pendingOrders);
      content = pendingItems.map((orderItem, itemIndex) => {
        totalAmount += orderItem.price * orderItem.quantity - (orderItem.item_discount || 0);
        return (
          <ListItem
            key={itemIndex}
            className="cart-item pb-3"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <div className="d-flex align-items-start justify-content-between w-100">
              <div className="d-flex flex-column col-6 pl-0">
                <p className="mb-0 font-weight-bold">{orderItem.product.data?.attributes.name}</p>
                <p className="product-variants mb-0">
                  {mergeToCommaSepString(
                    orderItem.variant.map((variant) => {
                      if (variant.quantity && variant.quantity > 1) {
                        return `x${variant.quantity} ${variant.variant_option.data.attributes.label}`;
                      }

                      return variant.variant_option.data.attributes.label;
                    })
                  )}
                </p>
              </div>
              <span className="quantity mb-0 mt-1 col-3">{orderItem.quantity}</span>
              <span className="price mb-0 mt-1 col-3 pr-0 d-flex justify-content-end">
                {formatCurrency(orderItem.price)}
              </span>
            </div>
          </ListItem>
        );
      });
    }
  } else {
    content = <Cart className="mb-9" cartItems={cartItems} />;
  }

  function handleTableActionsClick() {
    navigate(`/admin/table-actions/${currentTable?.id}`);
  }

  let header = null;
  if (mode == MODE_TABLES) {
    if (currentTable?.attributes?.name) {
      header = (
        <h3 className="mb-2">
          {t("table")} {currentTable?.attributes?.name}
        </h3>
      );
    } else if (currentTable?.id > 0) {
      header = <h3>{t("Pending")}</h3>;
    }
  } else {
    header = <h3>{t("Cart")}</h3>;
  }

  return (
    <Card className="h-100 cart-sidebar-desktop">
      <CardHeader>
        <div className="d-flex align-items-center justify-content-between w-100">{header}</div>
      </CardHeader>
      <CardBody className="pt-2">{content}</CardBody>

      {mode == MODE_TABLES && (
        <CardFooter className="d-flex justify-content-end">
          {totalAmount > 0 && (
            <div key="total" className="w-100 d-flex justify-content-start align-items-center">
              <div className="text-primary font-weight-bolder text-md-lg">{t("Total")}</div>
              <div className="text-primary font-weight-bolder text-md-lg ml-3">{formatCurrency(totalAmount)}</div>
            </div>
          )}
          <Button
            disabled={mode == MODE_TABLES && currentTable?.id < 0}
            type="button"
            color="primary"
            onClick={handleTableActionsClick}
          >
            {t("Actions")}
          </Button>
        </CardFooter>
      )}
    </Card>
  );
}
