import React, { useReducer } from "react";
// react library for routing
import { useLocation, Route, Routes } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { Col, Alert } from "reactstrap";
import menu from "menu.js";
import { useDispatch, useSelector } from "react-redux";
import { toggleCartOpen } from "redux/cart/cartSlice";
import { CartDrawer } from "components/ShoppingCart/CartDrawer";
import { selectCurrentUserRules, selectCurrentRole } from "auth/authSlice";
import CheckPermission from "auth/CheckPermission";
import { subscribeToOrdersChangeWS } from "config/websocket";
import { getOrganisation } from "api/ApiCallerHelper";
import { setSettings } from "redux/settings/settings";
import { apiOrders } from "api/ordersSlice";
import { TagTypes } from "utilities/enums/TagTypes";
import { t } from "i18next";
import { subscribeToSettingsChangeWS } from "config/websocket";
import { organisationSlice } from "api/organisationSlice";
import { CURRENT_UI_VERSION } from "utilities/constants";
import { useGetVersionQuery } from "api/appSlice";
import { checkVersionGreaterThan } from "utilities/utils";
import { Views } from "utilities/enums/Views";

function Admin() {
  const dispatch = useDispatch();
  const [sidenavOpenMobile, setSidenavOpenMobile] = React.useState(false);
  const [sidenavOpenDesktop, setSidenavOpenDesktop] = React.useState(true);
  const location = useLocation();
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const mainContentRef = React.useRef(null);
  const { cartItems, isOpen } = useSelector((state) => state.cart);
  let sidenavOpen = window.innerWidth > 1079 ? sidenavOpenDesktop : sidenavOpenMobile;
  const user_rules = useSelector(selectCurrentUserRules);
  const currentUserRole = useSelector(selectCurrentRole);
  const {
    payment_method_enabled,
    customers_per_order_enabled,
    cloud_cash_register_enabled,
    pos_integration_enabled,
    takeaway_enabled,
  } = useSelector((state) => state.settings);
  let settingsInvalid = payment_method_enabled === null || customers_per_order_enabled === null;
  const organisationId = useSelector((state) => state.auth.organisationId);

  function pinNav() {
    document.body.classList.remove("g-sidenav-hidden");
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.add("g-sidenav-pinned");
  }

  function unpinNav() {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-hidden");
    document.body.classList.add("g-sidenav-hidden");
  }

  function invalidateOrdersCache() {
    dispatch(apiOrders.util.invalidateTags([TagTypes.Orders]));
  }
  async function setApplicationSettings() {
    let organisation = await getOrganisation();
    dispatch(setSettings(organisation?.attributes));
  }

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1079) {
        unpinNav();
        setSidenavOpenMobile(false);
        sidenavOpen = sidenavOpenMobile;
        forceUpdate();
      } else {
        pinNav();
        setSidenavOpenDesktop(true);
        sidenavOpen = sidenavOpenDesktop;
        forceUpdate();
      }
    });

    if (organisationId) {
      subscribeToSettingsChangeWS(organisationId, async () => {
        dispatch(organisationSlice.util.invalidateTags([TagTypes.Organisation]));
        await setApplicationSettings();
      });

      subscribeToOrdersChangeWS(organisationId, invalidateOrdersCache);
    }
    if (settingsInvalid) {
      setApplicationSettings();
    }
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    // by default on desktop screens display sidenav pinned
    // by default on mobile/tablet screens display sidenav hidden
    if (window.innerWidth > 1079 && sidenavOpen && document.body.classList.contains("g-sidenav-hidden")) {
      pinNav();
      sidenavOpen = sidenavOpenDesktop;
      forceUpdate();
    }
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return <Route path={prop.path} element={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < menu.length; i++) {
      if (location.pathname.indexOf(menu[i].layout + menu[i].path) !== -1) {
        return menu[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }

    if (window.innerWidth > 1079) {
      setSidenavOpenDesktop(!sidenavOpenDesktop);
    } else {
      setSidenavOpenMobile(!sidenavOpenMobile);
    }
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1 ? "dark" : "light";
  };

  const getMenuRoutes = (routes) => {
    if (!takeaway_enabled) {
      // Include the takeaway menu only if takeaway is enabled
      routes = routes.filter((route) => route.name !== Views.TAKEAWAY);
    }
    if (!(cloud_cash_register_enabled && pos_integration_enabled)) {
      // Include the cash register management menu only if pos integration is enabled
      routes = routes.filter((route) => route.name !== Views.CASH_REGISTER_MANAGEMENT);
    }
    if (currentUserRole === "admin") {
      return routes;
    }
    const updatedRoutes = routes.map((route) => {
      if (route.collapse && route.views) {
        // if route has subroutes
        // check permissions for subroutes and remove subroutes that user has no access to
        const visableSubroutes = route.views.filter(
          (subroute) =>
            user_rules &&
            user_rules.filter((rule) => rule.name === `Route:${subroute.path}` && rule.value === true).length > 0
        );
        if (visableSubroutes.length > 0) {
          return {
            ...route,
            views: visableSubroutes,
          };
        }
      } else {
        // if route has no subroutes
        // check permissions for route
        if (
          user_rules &&
          user_rules.filter((rule) => rule.name === `Route:${route.path}` && rule.value === true).length > 0
        ) {
          return route;
        }
      }
      return undefined;
    });
    return updatedRoutes.filter((route) => route !== undefined);
  };

  return (
    <>
      <Sidebar
        routes={getMenuRoutes(menu)}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: currentUserRole === "admin" ? "/" : "/admin/tables",
          imgSrc: require("assets/img/brand/dito-logo-horizontal-transparent.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <CartDrawer
          cartItems={cartItems ?? []}
          open={isOpen}
          toggleCartOpen={() => {
            dispatch(toggleCartOpen());
          }}
          onClose={() => dispatch(toggleCartOpen())}
          onCheckout={() => {
            dispatch(toggleCartOpen());
          }}
        />
        {settingsInvalid && (
          <Col lg="12" className="container-fluid d-flex mb-0 bg-info">
            <Alert
              color="danger"
              className="col-lg-12 d-flex flex-column flex-md-row align-items-md-center align-items-start justify-content-between p-3"
            >
              <span className="alert-text ml-1 mb-0 mb-md-0 mr-md-4">
                <strong>{t("Error_fetching_settings_msg")}</strong>
              </span>
            </Alert>
          </Col>
        )}
        <Routes>
          <Route element={<CheckPermission />}>{getRoutes(menu)}</Route>
        </Routes>
        <AdminFooter />
      </div>
      {sidenavOpen && <div className="backdrop d-xl-none" onClick={toggleSidenav} />}
    </>
  );
}

export default Admin;
