import React from "react";
import { Card, CardHeader, CardBody, Row, Col, Input, FormGroup, Button } from "reactstrap";
import ActionsTable from "components/Tables/ActionsTable";
import { formatDateTime } from "utilities/utils";
import useOrdersTable from "components/OrdersTable/useOrdersTable";
import PuffLoader from "react-spinners/PuffLoader";
import { OrderStatus } from "utilities/enums/OrderStatus";
import { DatePicker } from "components/DatePicker/DatePicker";
import { formatDate } from "utilities/utils";
import AlertModal from "components/Modals/AlertModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CancellationReasonModal from "components/Modals/CancellationReasonModal";
import GiftConfirmationModal from "components/Modals/GiftConfirmationModal";
import ViewOrder from "views/pages/orders/ViewOrder";

export const OrdersTable = ({ title, subtitle, pageSize }) => {
  const { t } = useTranslation();
  const { locale } = useSelector((state) => state.locale);
  const { cloud_cash_register_enabled } = useSelector((state) => state.settings);
  const {
    currentOrdersPage,
    handlePagination,
    isLoading,
    isError,
    orders,
    handleViewOrder,
    handleCancelOrder,
    isOrderPending,
    filterModel,
    handleFiltersModalOpen,
    handleReactDatetimeChange,
    resetFilterModel,
    saveFilters,
    removeFilterTag,
    tempFilterModel,
    setTempFilterModel,
    filtersModalOpen,
    setFiltersModalOpen,
    getFilters,
    tables,
    cancellationModalOpen,
    setCancellationModalOpen,
    giftModalOpen,
    setGiftModalOpen,
    selectedRow,
    cancelOrder,
    updateOrderStatusLoading,
    handleGiftOrder,
    giftOrder,
    viewOrderModalOpen,
    setViewOrderModalOpen,
    allow_discount,
    users,
    canReturnInventory,
    wholesale_enabled,
    helper_wholesale_receipts_enabled,
    bulkUpdateLoading,
  } = useOrdersTable({ pageSize });

  const tableCols = [
    { label: "id", mobileVisible: false },
    { label: t("date_submitted"), translateDate: true, formatter: formatDateTime, mobileVisible: true },
    { label: t("User"), mobileVisible: false },
    {
      label: t("table"),
      mobileVisible: true,
      formatter: (name) => {
        return name || t("Takeaway");
      },
    },
    { label: t("price"), mobileVisible: false },
    { label: t("status"), mobileVisible: true },
  ];
  if (isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <PuffLoader loading={isLoading} color="#d9d9d9" size={50} aria-label="Loading Spinner" data-testid="loader" />
      </div>
    );
  }
  if (isError) {
    return <div>Error</div>;
  }
  const filterTags = (
    <>
      {filterModel.status && (
        <div className="filter-tag">
          <div className="text">{t(filterModel.status)}</div>
          <span className="remove" onClick={() => removeFilterTag("status")}></span>
        </div>
      )}
      {filterModel.user && (
        <div className="filter-tag">
          <div className="text">
            {t("User")}: {filterModel.user}
          </div>
          <span className="remove" onClick={() => removeFilterTag("user")}></span>
        </div>
      )}
      {filterModel.table && (
        <div className="filter-tag">
          <div className="text">
            {t("table")}: {filterModel.table}
          </div>
          <span className="remove" onClick={() => removeFilterTag("table")}></span>
        </div>
      )}
      {(filterModel.startDate || filterModel.endDate) && (
        <div className="filter-tag">
          <div className="text">
            {t("Date", { date: formatDate(filterModel.startDate, locale) })} -{" "}
            {t("Date", { date: formatDate(filterModel.endDate, locale) })}
          </div>
          <span className="remove" onClick={() => removeFilterTag("dates")}></span>
        </div>
      )}
      {filterModel.invoice_select && (
        <div className="filter-tag">
          <div className="text">{t(filterModel.invoice_select)}</div>
          <span className="remove" onClick={() => removeFilterTag("invoice_select")}></span>
        </div>
      )}
      {filterModel.order_type && (
        <div className="filter-tag">
          <div className="text">{t(filterModel.order_type)}</div>
          <span className="remove" onClick={() => removeFilterTag("order_type")}></span>
        </div>
      )}
    </>
  );

  return (
    <Card className="orders-table-card mb-0 h-100">
      <CardHeader className="bg-transparent pb-2 pt-4 pl-3 pr-3 pl-md-4">
        <Row className="align-items-center w-100 ml-0">
          <Col xs="6" md="3" className="pl-0">
            <h5 className="h3 mb-0">{title ?? ""}</h5>
            <h6 className="text-light text-uppercase ls-1 mb-1">{subtitle ?? ""}</h6>
          </Col>
          <Col xs="6" md="9" className="pr-0 d-flex justify-content-end">
            <div className="filter-tags desktop">{filterTags}</div>

            <Button
              className="btn btn-primary filter-icon"
              type="button"
              color="primary"
              size="sm"
              onClick={handleFiltersModalOpen}
            >
              {t("Filters")}
            </Button>
          </Col>
        </Row>
        <Row className="filter-tags mobile ml-0 mr-0">{filterTags}</Row>
      </CardHeader>
      <CardBody>
        <ActionsTable
          columns={tableCols}
          data={orders?.data}
          totalData={orders?.meta.pagination.total}
          pageSize={pageSize}
          paginationAction={handlePagination}
          page={currentOrdersPage}
          onRowClick={handleViewOrder}
          actions={[
            { label: t("View"), handler: handleViewOrder },
            { label: t("Cancel"), handler: handleCancelOrder, isActionVisible: isOrderPending },
            { label: t("Gift"), handler: handleGiftOrder, isActionVisible: isOrderPending },
          ]}
        />
      </CardBody>

      {selectedRow && (
        <ViewOrder
          isOpen={viewOrderModalOpen}
          onCancel={() => setViewOrderModalOpen(false)}
          orderId={selectedRow?.id}
        />
      )}

      <AlertModal
        isOpen={filtersModalOpen}
        onCancel={() => setFiltersModalOpen(false)}
        title={t("Filters")}
        size="lg"
        body={
          <>
            <Row>
              <Col md="6" sm="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="start-date">
                    {t("From")}
                  </label>
                  <DatePicker
                    id="start-date"
                    selectedDate={tempFilterModel.startDate}
                    startDate={tempFilterModel.startDate}
                    endDate={tempFilterModel.endDate}
                    onChange={(e) => handleReactDatetimeChange("startDate", e)}
                    placeholder={t("From")}
                  />
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="end-date">
                    {t("To")}
                  </label>
                  <DatePicker
                    selectedDate={tempFilterModel.endDate}
                    startDate={tempFilterModel.startDate}
                    endDate={tempFilterModel.endDate}
                    onChange={(e) => handleReactDatetimeChange("endDate", e)}
                    placeholder={t("To")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="status">
                    {t("status")}
                  </label>
                  <Input
                    className="mb-3"
                    type="select"
                    id="status"
                    placeholder="Status"
                    value={tempFilterModel.status}
                    onChange={(e) => {
                      setTempFilterModel((prev) => {
                        return {
                          ...prev,
                          status: e.target.value,
                          filters: getFilters({ ...prev, status: e.target.value }),
                        };
                      });
                    }}
                  >
                    <option value="">{t("All")}</option>
                    <option value={OrderStatus.Pending}>{t("Pending")}</option>
                    <option value={OrderStatus.Payed}>{t("Payed_plural")}</option>
                    <option value={OrderStatus.Cancelled}>{t("Canceled_plural")}</option>
                    {allow_discount && <option value={"discounted"}>{t("Discounted_Orders")}</option>}
                    <option value={"gifted"}>{t("Gifted_Orders")}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="order_type">
                    {t("Order_type_external_src")}
                  </label>
                  <Input
                    className="mb-3"
                    type="select"
                    id="order_type"
                    placeholder="Order Type"
                    value={tempFilterModel.order_type}
                    onChange={(e) => {
                      setTempFilterModel((prev) => {
                        return {
                          ...prev,
                          order_type: e.target.value,
                          filters: getFilters({ ...prev, order_type: e.target.value }),
                        };
                      });
                    }}
                  >
                    <option value="">{t("All")}</option>
                    <option value={"wolt"}>{t("Wolt")}</option>
                    <option value={"efood"}>{t("Efood")}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="table">
                    {t("table")}
                  </label>
                  <Input
                    className="mb-3"
                    type="select"
                    id="status"
                    placeholder="Status"
                    value={tempFilterModel.table}
                    onChange={(e) => {
                      setTempFilterModel((prev) => {
                        return {
                          ...prev,
                          table: e.target.value,
                          filters: getFilters({ ...prev, table: e.target.value }),
                        };
                      });
                    }}
                  >
                    <option value="">{t("All")}</option>
                    {tables?.map((table) => {
                      return (
                        <option key={table.id} value={table.attributes.name}>
                          {table.attributes.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              {users && users.length > 0 && (
                <Col sm="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="user">
                      {t("User")}
                    </label>
                    <Input
                      className="mb-3"
                      type="select"
                      id="user"
                      placeholder="user"
                      value={tempFilterModel.user}
                      onChange={(e) => {
                        setTempFilterModel((prev) => {
                          return {
                            ...prev,
                            user: e.target.value,
                            filters: getFilters({ ...prev, user: e.target.value }),
                          };
                        });
                      }}
                    >
                      <option value="">{t("All_plural_male")}</option>
                      {users?.map((user) => {
                        return (
                          <option key={user.id} value={user.username}>
                            {user.username}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              )}

              {cloud_cash_register_enabled && (
                <Col sm="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="inovice_filter">
                      {t("Invoice_Type")}
                    </label>
                    <Input
                      className="mb-3"
                      type="select"
                      id="inovice_filter"
                      placeholder="Invoice Type"
                      value={tempFilterModel.invoice_select}
                      onChange={(e) => {
                        setTempFilterModel((prev) => {
                          return {
                            ...prev,
                            invoice_select: e.target.value,
                            filters: getFilters({ ...prev, invoice_select: e.target.value }),
                          };
                        });
                      }}
                    >
                      <option value="">{t("All")}</option>
                      <option value="Without_Invoice">{t("Without_Invoice")}</option>
                      <option value="retail">{t("retail")}</option>
                      <option value="opentable">{t("OpenTable_invoice")}</option>
                      {wholesale_enabled && <option value="wholesale">{t("wholesale")}</option>}
                      {helper_wholesale_receipts_enabled && (
                        <option value="helperWholesale">{t("helperWholesale")}</option>
                      )}
                      <option value="cancelled_invoice">{t("cancelled_invoice")}</option>
                      <option value="error_invoice">{t("error_invoice")}</option>
                    </Input>
                  </FormGroup>
                </Col>
              )}
            </Row>

            <Row>
              <Col xs="12" className="d-flex justify-content-between">
                <button className="btn btn-outline-default" color="primary" type="button" onClick={resetFilterModel}>
                  {t("Reset")}
                </button>
                <button className="btn btn-primary" onClick={saveFilters}>
                  {t("Save")}
                </button>
              </Col>
            </Row>
          </>
        }
      />

      <CancellationReasonModal
        isOpen={cancellationModalOpen}
        title={`${t("Cancel_order")} ${selectedRow?.id}`}
        okText={t("Submit")}
        cancelText={t("Discard")}
        onCancel={() => setCancellationModalOpen(false)}
        onOk={cancelOrder}
        showReturnInventory={canReturnInventory}
        okDisabled={bulkUpdateLoading || updateOrderStatusLoading}
      />

      <GiftConfirmationModal
        isOpen={giftModalOpen}
        title={`${t("Gift_order")} ${selectedRow?.id}`}
        okText={t("Gift")}
        cancelText={t("Cancel")}
        onCancel={() => setGiftModalOpen(false)}
        onOk={giftOrder}
        okDisabled={bulkUpdateLoading || updateOrderStatusLoading}
        selectedOrderTotal={selectedRow?.totalPrice}
      />
    </Card>
  );
};
