import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useGetAllCategoriesQuery } from "api/categoriesSlice";
import DefaultLayout from "templates/DefaultLayout";
import NewOrderFooter from "components/Footers/NewOrderFooter";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import { selectCartTable } from "redux/cart/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { setCartTable } from "redux/cart/cartSlice";
import NotificationAlert from "react-notification-alert";
import { triggerNotification } from "utilities/utils";
import { TAKEAWAY_FUNNEL_NAME } from "utilities/constants";

export default function Takeaway() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location
  const queryParams = new URLSearchParams(location.search); // Parse the query parameters

  const table = { id: null, attributes: { name: TAKEAWAY_FUNNEL_NAME } };
  const { data: categories, isLoading, isError } = useGetAllCategoriesQuery();
  const { t } = useTranslation();
  const notificationAlertRef = useRef(null);
  const [notificationShown, setNotificationShown] = useState(false);

  let header = <h3 className="mb-0">{t(Views.CATEGORIES)}</h3>;

  function handleCategoryClick(category) {
    let url = `/admin/takeaway/${category.id}/${category.hasSubcategories ? "subcategories" : "products"}`;
    navigate(url, {
      state: { parentCategoryName: category.name },
    });
  }

  useEffect(() => {
    if (queryParams.get("success") && !notificationShown) {
      if (notificationAlertRef.current) {
        triggerNotification(notificationAlertRef, "success", t("Takeaway"), t("Takeaway_msg_success"));
        setNotificationShown(true);
        queryParams.delete("success");
        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
      }
    }
  }, [notificationShown]);

  useEffect(() => {
    dispatch(setCartTable(table));
  }, []);
  return (
    <>
      <DefaultLayout
        name={Views.CATEGORIES}
        parentName={Views.TAKEAWAY}
        isLoading={isLoading}
        isError={isError}
        header={header}
        hasBackButton={false}
        hasSideBar={true}
        table={table}
        classNames="cart-sidebar-enabled"
      >
        <Row className="row-shadow">
          {categories?.map((category) => {
            return (
              <Col sm="6" md="4" lg="3" key={category.id} onClick={() => handleCategoryClick(category)}>
                <span className="category-card-container btn text-center d-flex flex-wrap flex-md-row justify-content-center align-items-center p-1">
                  {category.photo ? (
                    <div className="col-4 col-md-auto image">
                      <img
                        alt="..."
                        className="image-preview rounded shadow"
                        src={`${category.photo?.attributes.url}`}
                      />
                    </div>
                  ) : null}
                  <div
                    className={`${
                      category.photo ? "col-8 justify-content-start" : "col-12 justify-content-center"
                    } col-md-12 d-flex justify-content-start justify-content-md-center mt-1`}
                  >
                    {category.name}
                  </div>
                </span>
              </Col>
            );
          })}
        </Row>

        <div className="rna-wrapper">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
      </DefaultLayout>
      <NewOrderFooter />
    </>
  );
}
