import React from "react";
import { Card, CardBody, Row, Button, Col } from "reactstrap";
import { formatCurrency } from "utilities/utils";
import DefaultLayout from "templates/DefaultLayout";
import NewOrderFooter from "components/Footers/NewOrderFooter";
import { Views } from "utilities/enums/Views";
import { increaseCartQuantity, decreaseCartQuantity } from "redux/cart/cartSlice";
import useTakeawayProducts from "./useTakeawayProducts";
import AlertModal from "components/Modals/AlertModal";
import NewOrderProduct from "views/pages/tables/NewOrder/Product/NewOrderProduct";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { MODE_CREATE } from "utilities/constants";

export default function TakeawayProducts() {
  const {
    dispatch,
    products,
    isLoading,
    isError,
    handleProductClick,
    getTotalAlreadyOrdered,
    getItemQuantity,
    selectedProduct,
    handleProductClose,
    parentCategory,
    cartTable,
  } = useTakeawayProducts();
  const { t } = useTranslation();
  const location = useLocation();

  let parentCategoryName = location.state?.parentCategoryName;
  let headerContent = parentCategoryName ? parentCategoryName : t(Views.PRODUCTS);
  let header = <h3 className="mb-0">{headerContent}</h3>;

  function getProductQuantity(product) {
    return (
      <div className="quantity-container pl-0 pt-1 ml-auto">
        {product.inventoryEnabled && product.inventory <= 0 ? (
          <div className="quantity text-danger">{t("Out_of_stock")}</div>
        ) : (
          <div className="quantity text-success">
            {product.inventoryEnabled ? `${t("Quantity")}: ${product.inventory}` : null}
          </div>
        )}
      </div>
    );
  }
  return (
    <DefaultLayout
      name={Views.PRODUCTS}
      parentName={Views.NEW_ORDER}
      isLoading={isLoading}
      isError={isError}
      hasSideBar={true}
      hasBackButton={true}
      header={header}
      table={cartTable}
      classNames="cart-sidebar-enabled new-order-products-view"
    >
      <Row className="row-shadow">
        {products
          ? products.map((product) => {
              const cartProduct = {
                id: product.id,
                name: product.name,
                variants: [],
                price: product.price,
                category: parentCategory,
                vat_category: product.vat_category,
              };
              return (
                <Col key={product.id} xs="12" sm="6">
                  <Card className="mb-3">
                    <CardBody
                      className="d-flex flex-wrap flex-sm-row text-center pt-3-md pb-4-md p-2 justify-content-between justify-content-md-start align-items-center"
                      onClick={() => handleProductClick(product)}
                      role="button"
                    >
                      <div className="d-flex col-md-12 flex-wrap flex-row justify-content-start align-items-center pr-0 pl-2">
                        <div className="pl-0 col-8 product-info d-flex w-100 flex-column align-items-start pr-2 pr-sm-3 pr-lg-2">
                          <h5 className="h3 title mb-1 text-left product-name" role="button">
                            {product.name}
                          </h5>
                          <div className="price text-left text-md-center">{formatCurrency(product.price)}</div>
                        </div>

                        <div className="col-4 p-0 d-flex flex-wrap justify-content-column">
                          <div className="pl-0 pr-0 d-flex justify-content-end align-items-center buttons-container w-100 mt-md-2">
                            <Button
                              className="btn-icon-only product-quantity-btn-circle rounded-circle"
                              color="danger"
                              onClick={(e) => {
                                dispatch(decreaseCartQuantity(cartProduct));
                                e.stopPropagation();
                              }}
                              disabled={getItemQuantity(cartProduct) <= 0}
                            >
                              <i className="fa fa-minus" />
                            </Button>
                            <div className="quantity">{getItemQuantity(cartProduct)}</div>
                            <Button
                              className="btn-icon-only product-quantity-btn-circle rounded-circle ml-2"
                              color="success"
                              onClick={(e) => {
                                dispatch(increaseCartQuantity(cartProduct));
                                e.stopPropagation();
                              }}
                              disabled={
                                product.inventoryEnabled && product.inventory <= getTotalAlreadyOrdered(cartProduct)
                              }
                            >
                              <i className="fa fa-plus" />
                            </Button>
                          </div>
                          {getProductQuantity(product)}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })
          : []}
      </Row>

      <AlertModal
        isOpen={selectedProduct != null}
        onCancel={handleProductClose}
        headerImage={selectedProduct?.image?.attributes.url}
        stickyHeaderEnabled={true}
        header={
          <div className="position-relative w-100 margin-top--35">
            <button
              aria-label="Close"
              className="close position-absolute right-3 top-0"
              data-dismiss="modal"
              type="button"
              onClick={handleProductClose}
            ></button>
            <div className="modal-header pb-0 d-flex flex-column pt-0">
              <h2 className="modal-title pr-4 mt-1" id="modal-title-default">
                {selectedProduct?.name}
              </h2>
              <h5 className="modal-subtitle text-muted font-weight-light mt-2">{selectedProduct?.description}</h5>
              <div className="text-primary">{formatCurrency(selectedProduct?.price)}</div>
            </div>
          </div>
        }
        stickyHeader={
          <div>
            <button
              aria-label="Close"
              className="close position-absolute right-3 top-2-5"
              data-dismiss="modal"
              type="button"
              onClick={handleProductClose}
            ></button>
            <div className="modal-header pb-0 d-flex flex-column pt-3">
              <h2 className="modal-title pr-4" id="modal-title-default">
                {selectedProduct?.name}
              </h2>
            </div>
          </div>
        }
        body={
          <NewOrderProduct
            mode={MODE_CREATE}
            productID={selectedProduct ? selectedProduct.id : -1}
            addproductCallback={handleProductClose}
          />
        }
      />
      <NewOrderFooter />
    </DefaultLayout>
  );
}
