export const Views = Object.freeze({
  LOGIN: "Login",
  DASHBOARD: "Dashboard",
  ORDERS: "Orders",
  TABLES: "Tables",
  ANALYTICS: "Analytics",
  TABLE_MANAGEMENT: "Table_Management",
  TABLE_ACTIONS: "Table Actions",
  FLOORS: "Floors",
  MENU_MANAGEMENT: "Menu_Management",
  SETTINGS: "Settings",
  NEW_ORDER: "New Order",
  CATEGORIES: "Categories",
  SUBCATEGORIES: "Subcategories",
  EDIT_PRODUCT: "Edit_Product",
  PRODUCT: "Product",
  PRODUCTS: "Products",
  VARIATIONS: "Variations",
  VARIATION_OPTIONS: "Variation_Options",
  VIEW_ORDER: "View_Order",
  PAY_ITEMS: "Pay_Items",
  CANCEL_GIFT: "Cancel_Gift",
  TRANSFER_ITEMS: "Transfer",
  RESUBMIT_ITEMS: "Resubmit",
  TABLE_SUMMARY: "Table_Summary",
  USER_MANAGEMENT: "User_Management",
  PERMISSION_MANAGEMENT: "Permissions_Management",
  PRINTER_MANAGEMENT: "Printers_Management",
  PRINT_SCENARIOS: "Print_scenarios",
  GENERAL_SETTINGS: "General",
  EDIT_PRINTER: "Edit_Printer",
  GUIDES: "Guides",
  UNAUTHORIZED: "Unauthorized",
  CASH_REGISTER_MANAGEMENT: "Cash_register_management",
  POS_MANAGEMENT: "Pos_terminals",
  TAKEAWAY: "Takeaway",
});
