export const WS_EVENT_JOIN = "joinOrg";
export const WS_EVENT_WELCOME = "welcome";
export const WS_EVENT_SUBMIT_ORDERS_CHANGE = "submitOrdersChange";
export const WS_EVENT_ORDERS_CHANGE = "ordersChange";
export const WS_EVENT_SUBMIT_CHART_CHANGE = "submitChartChange";
export const WS_EVENT_CHART_CHANGE = "chartChange";
export const WS_EVENT_SUBMIT_SETTINGS_CHANGE = "submitSettingsChange";
export const WS_EVENT_SETTINGS_CHANGE = "settingsChange";
export const CANCELLATION_REASON_GIFT = "CANCELLATION_REASON_GIFT";
export const WS_EVENT_USER_CHANGE = "userChange";
export const WS_EVENT_USER_CHANGE_START = "userChangeStart";
export const MODE_CREATE = "mode_create";
export const MODE_UPDATE = "mode_update";
export const PRINTING_CASES = {
  ORDER_SUBMITTION: "order_submittion",
  ORDER_PAYMENT: "order_payment",
};
export const MODE_RESUBMIT = "mode_update";

export const PUBLIC_ROUTES = ["Route:login", "Route:unauthorized"];
export const USER_ROLES = ["admin", "manager", "staff"];
export const CURRENT_UI_VERSION = process.env.REACT_APP_VERSION;
export const TAKEAWAY_FUNNEL_NAME = "Takeaway_funnel_custom_name";
let BACKEND_BASE_URL = `http://localhost:1337`;
let WEB_PUSH_PUBLIC_KEY = "BCFFD304x8N158AVW2N3t6j-g8aHIfP0sE4bLavSk5nz4Df79DXBLPfLNBJF7w3Bljw8xOb6wLulnksjH17QTLU";
if (!process.env.REACT_APP_DEPLOYMENT_ENV || process.env.REACT_APP_DEPLOYMENT_ENV === "development") {
  BACKEND_BASE_URL = `http://localhost:1337`;
} else if (process.env.REACT_APP_DEPLOYMENT_ENV === "staging") {
  BACKEND_BASE_URL = `https://backend.pos-staging.ditoapp.gr`;
} else if (process.env.REACT_APP_DEPLOYMENT_ENV === "do-staging") {
  BACKEND_BASE_URL = `https://backend-pos-staging.ditoapp.gr`;
} else if (process.env.REACT_APP_DEPLOYMENT_ENV === "do-demo") {
  BACKEND_BASE_URL = `https://backend-pos-demo.ditoapp.gr`;
} else if (process.env.REACT_APP_DEPLOYMENT_ENV === "do-prod") {
  BACKEND_BASE_URL = `https://backend-pos.ditoapp.gr`;
  WEB_PUSH_PUBLIC_KEY = "BMcmY0ywQG7EwB7tb_3La07ykprznzuixGdU71kVBLtuoAgAX--cA9ZJE670_63aXxGvZDMmIfM-ppIqhRRsFD4";
}

let REACT_APP_BACKEND_URL = `${BACKEND_BASE_URL}/api`;
let REACT_APP_ANALYTICS_URL = `${BACKEND_BASE_URL}/analytics`;
let REACT_APP_DITOPW_URL = `${BACKEND_BASE_URL}`;

const RECEIPT_TYPES = {
  internal: "internal",
  openTable: "openTable",
  openTableInvoice: "openTableInvoice",
  grReceipt: "grReceipt",
  helperWholesale: "helperWholesale",
  wholesale: "wholesale",
};

export const draggableDraggingStyles = {
  backgroundColor: "#ffffff",
  left: "50% !important",
  margin: "0 auto !important",
  width: "100% !important",
  display: "table !important",
  minWidth: "450px !important",
  minHeight: "60px !important",
};
export const draggableCategoriesDraggingStyles = {
  backgroundColor: "#ffffff",
  left: "0% !important",
  margin: "0 auto !important",
  width: "100% !important",
  height: "80px !important",
  display: "table !important",
  minWidth: "450px !important",
  minHeight: "60px !important",
};
export {
  BACKEND_BASE_URL,
  REACT_APP_BACKEND_URL,
  REACT_APP_ANALYTICS_URL,
  REACT_APP_DITOPW_URL,
  RECEIPT_TYPES,
  WEB_PUSH_PUBLIC_KEY,
};
